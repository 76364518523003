import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { EXTERNAL_URLS } from 'consts/externalUrls';

import { MissionBox, mobileImageStyles, desktopImageStyles } from './style';

const MissionSection = () => (
  <React.Fragment>
    <MissionBox>
      <p>
        We’re a fast-growing tech company{' '}
        <strong>on a mission to make prescription skincare accessible.</strong>{' '}
        Because everyone deserves to feel at home in their skin.
      </p>
      <p>While we’re at it, we put people first—and others think so, too:</p>
      <ul>
        <li>
          <a
            href={EXTERNAL_URLS.gptwReference}
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Great Place to Work</strong>
          </a>
        </li>
        <li>
          <a
            href={EXTERNAL_URLS.forbesReference}
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Forbes’ #4 Best Startup Employers</strong>
          </a>
        </li>
        <li>
          <a
            href={EXTERNAL_URLS.fortuneReference}
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Fortune Best Medium Workplaces</strong>
          </a>
        </li>
      </ul>
      <p>
        We have offices in San Diego and San Francisco—but many of our roles are
        remote-friendly!
      </p>
    </MissionBox>
    <StaticImage
      src="./content/images/ourMissionBannerMobile@2x.jpg"
      alt="photo of Curology employees"
      layout="fullWidth"
      css={mobileImageStyles}
    />
    <StaticImage
      src="./content/images/ourMissionBannerDesktop@2x.jpg"
      alt="photo of Curology employees"
      width={936}
      css={desktopImageStyles}
    />
  </React.Fragment>
);

export default MissionSection;
